<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents">
      <div class="d-flex ai-center">
        <div class="img bg-color6 d-flex ai-center jc-center">
          <van-image
            :src="detail.remote_url"
            fit="cover"
            width="auto"
            height="70px"
          />
        </div>
        <div class="fs-lg m-l-15">
          <p class="bold">{{ $t("home.info") }}</p>
          <p v-if="detail.discount == 1">--</p>
          <p class="t-color7 m-t-5 m-b-10" style="letter-spacing: 0.5px" v-else>
            <span class="t-color4 bold"
              >[{{ $t("levels.level" + detail.level) }}] </span
            ><span>{{ $t("home.rate") }}: </span
            ><span class="t-color5">{{ detail.discount | cutZero }}</span>
          </p>
          <van-button
            color="#074CE7"
            class="buy_btn"
            size="small"
            :loading="isloading"
            :disabled="isdisabled"
            @click="onBuy()"
            >{{ ratoNumber }} BNB
            <span class="fs-xl">{{ $t("home.buy") }}</span></van-button
          >
        </div>
      </div>
      <div class="d-flex ai-center jc-between m-t-20">
        <p class="t-color2">{{ $t("market.name") }}</p>
        <p class="bold">
          {{ lang == "zh" ? detail.nft_name : detail.nft_name_en }}
        </p>
      </div>
      <div class="d-flex ai-center jc-between m-t-20">
        <p class="t-color2">{{ $t("market.sell") }}</p>
        <p class="bold t-color5">{{ detail.price | cutZero }} BNB</p>
      </div>
      <div class="d-flex ai-center jc-between m-t-20">
        <p class="t-color2">{{ $t("market.games") }}</p>
        <p class="bold">
          {{ lang == "zh" ? detail.game_name : detail.game_name_en }}
        </p>
      </div>
      <div class="d-flex ai-center jc-between m-t-20">
        <p class="t-color2">Token</p>
        <div class="d-flex ai-center">
          <p class="m-r-5">{{ detail.token_id }}</p>
          <van-image
            width="15px"
            height="15px"
            :src="require('@/assets/copy.png')"
            v-clipboard:copy="detail.token_id"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          />
        </div>
      </div>
      <div class="t-color2 fs-md m-t-20">
        <p class="fs-xxl">{{ $t("market.introduce") }}</p>
        <p
          class="m-t-10 lh-2 word"
          v-html="lang == 'zh' ? detail.nft_info : detail.nft_info_en"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import abi_G from "@/common/abi/abi_G.json";
import { mapState } from "vuex";
export default {
  data() {
    return {
      detail: {},
      isloading: false,
      contract_address: "", //游戏道具合约
      isdisabled: true,
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
    ratoNumber() {
      return BigNumber(this.detail.price).times(this.detail.discount).toFixed();
    },
    ...mapState({
      balance: (state) => state.balance,
      setting: (state) => state.setting,
      walletAddress: (state) => state.walletAddress,
    }),
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.$store.dispatch("getBalance"); //钱包余额
  },
  mounted() {
    this.getList();
    this.getSet();
  },
  methods: {
    //复制成功
    onCopy() {
      this.$toast({
        message: this.$t("public.copySuc"),
        duration: 1000,
      });
    },
    //复制失败
    onError() {
      this.$toast({
        message: this.$t("public.copyCancel"),
        duration: 1000,
      });
    },
    getList() {
      this.$axios
        .get(this.$api.gamefinftdetail + "/" + this.$route.query.id, {})
        .then((res) => {
          if (res.code == 200) {
            // -1=>'待售',0=>'预买',1=>'已成交'  只有等于 -1才能购买
            if (res.data.status == -1) {
              this.isdisabled = false;
            } else {
              this.isdisabled = true;
            }
            this.detail = res.data;
          }
        })
        .catch((err) => {
          this.isdisabled = true;
        });
    },
    //获取网站配置
    getSet() {
      if (this.setting) {
        let set = JSON.parse(this.setting);
        set.forEach((element) => {
          if (element.id == 55) {
            //游戏道具合约
            this.contract_address = element.value;
          }
        });
      }
    },
    //购买nft
    onBuy() {
      let value = BigNumber(this.balance)
        .comparedTo(this.ratoNumber)
        .toString(); //1为大于，-1为小于，0为等于
      if (value == -1) {
        this.$toast(this.$t("public.noblance"));
      } else {
        this.isloading = true;
        this.$axios
          .post(this.$api.gamefinftbuy + "/" + this.$route.query.id, {})
          .then((res) => {
            if (res.code == 200) {
              this.onbuyNft(res.data);
            } else {
              this.isloading = false;
              this.$toast(res.msg);
            }
          });
      }
    },
    //先调接口，用接口返回值 作为参数 调用合约的方法
    async onbuyNft(data) {
      let amount =
        "0x" +
        BigNumber(data.price)
          .toString(16)
          .replace(/\.\w+$/, "");
      let contract = new web3.eth.Contract(abi_G, this.contract_address);
      await contract.methods
        .buyNft(
          data.nft_address,
          data.nft_id,
          data.token_id,
          "0x" + BigNumber(data.price).toString(16),
          data.user_address,
          data.userid,
          data.noce,
          "0x" + BigNumber(data.timestamp).toString(16),
          data.receive_bnb_address,
          data.sign
        )
        .send({ from: this.walletAddress, value: amount }, (err, res) => {
          if (res) {
            this.isloading = false;
            this.$toast(this.$t("public.suc"));
            setTimeout(() => {
              this.$router.replace({
                path: "/gameDetail",
                query: { id: this.$route.query.ids },
              });
            }, 500);
          }
          if (err) {
            this.isloading = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  padding: 15px 35px;
  .img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
  .buy_btn {
    width: 164px;
    border-radius: 5px;
  }
}
</style>